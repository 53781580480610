
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import VideoDetails from '@/components/shared/VideoDetails/VideoDetails.vue';

import { VideoDetailsData, VideoSource, VideoUrlValidate } from '@/models';
import { VimeoVideoDetails, YoutubeVideoDetails } from '@/models/api';

import { VideoService } from '@/services';

@Component({
  name: 'VideoSearch',
  components: {
    VideoDetails,
  },
})
export default class VideoSearch extends Vue {
  $refs!: {
    videoSearchInput: HTMLInputElement;
  };

  @Prop({ default: 'Link do YouTube ou Vimeo' }) placeholder: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ required: false, default: '' }) value!: string;

  private debounce: number;
  public videoData: VideoDetailsData | null = null;
  public linkToSearch = '';
  public alreadyInput = false;

  mounted() {
    if (this.value) this.onSearch();
  }

  get link() {
    return (this.linkToSearch.length || this.alreadyInput)
      ? this.linkToSearch
      : this.value;
  }

  set link(url: string) {
    this.linkToSearch = url;
  }

  public debounceOnSearch(): void {
    this.alreadyInput = true;
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => this.onSearch(), 300);
  }

  private onSearch(): void {
    const value = this.$refs.videoSearchInput.value.trim();
    this.output(value);

    if (!value.length) {
      this.videoData = null;
      return;
    }

    const info = VideoService.validateVideoUrl(value);

    if (info.source !== VideoSource.INVALID) {
      this.getVideo(info);
    }

    this.videoData = this.makeVideoData(info);
  }

  private async getVideo(info: VideoUrlValidate) {
    try {
      if (info.urlApi) {
        const { data } = await VideoService.getVideoDetails(info.urlApi);
        this.videoData = this.makeVideoData(info, data);
      }
    } catch (error) {
      this.videoData = this.makeVideoData({ source: VideoSource.INVALID });
    }
  }

  public output(url: string) {
    this.$emit('change', url);
  }

  public makeVideoData(
    info: VideoUrlValidate,
    data?: YoutubeVideoDetails | VimeoVideoDetails,
  ): VideoDetailsData {
    const { source, url } = info;

    return {
      source,
      ...(url && { url }),
      ...(data && { data }),
    };
  }
}
