
import { Component, Prop, Vue } from 'vue-property-decorator';

import BackArrow from '@/assets/icons/BackArrow.svg';

import ButtonDefault from '@/components/shared/ButtonDefault.vue';

@Component({
  name: 'Toolbar',
  components: {
    ButtonDefault,
    BackArrow,
  },
})
export default class Toolbar extends Vue {
  @Prop({ default: false }) public hasUnsavedChanges!: boolean;
  @Prop({ default: false }) public saving!: boolean;
  @Prop({ default: false }) public hideSaveDraft!: boolean;
  @Prop({ default: false }) public enablePublish!: boolean;
  @Prop({ default: false }) public enableDraft!: boolean;
  @Prop({ default: '' }) public buttonTextDraft!: string;
  @Prop({ default: '' }) public buttonTextPublish!: string;

  public output(event: string) {
    this.$emit(event);
  }
}
