
import { Component, Prop, Vue } from 'vue-property-decorator';

import Youtube from '@/assets/icons/Youtube.svg';
import Vimeo from '@/assets/icons/Vimeo.svg';

import VideoDetails from '@/components/shared/VideoDetails/VideoDetails.vue';

import { ThumbDetails, VideoSource } from '@/models';

import { VideoService } from '@/services';

import VideoDetailsFactory from './VideoDetails/VideoDetailsFactory.vue';

@Component({
  name: 'VideoSearch',
  components: {
    VideoDetails,
    Vimeo,
    Youtube,
  },
})
export default class VideoSearch extends Vue {
  @Prop({ required: true }) url: string;

  $refs!: {
    iframe: HTMLIFrameElement;
  };

  public play = false;
  public thumbnail = '';
  public overlay = true;

  private allowParameters = [
    'fullscreen',
    'accelerometer',
    'autoplay',
    'clipboard-write',
    'encrypted-media',
    'gyroscope',
    'picture-in-picture',
  ];

  public INVALID = VideoSource.INVALID;
  public YOUTUBE = VideoSource.YOUTUBE;
  public VIMEO = VideoSource.VIMEO;
  public source = '';

  mounted() {
    this.getThumbnail();
  }

  updated() {
    this.getThumbnail();
  }

  public playVideo() {
    this.play = true;
    setTimeout(() => (this.overlay = false), 1000);
  }

  public get warningComopnet() {
    return VideoDetailsFactory.makeThumb(ThumbDetails.WARNING);
  }

  public get iframeUrl() {
    const infos = this.videoInfos;
    return `${VideoService.getUrlIframe(infos)}?autoplay=${this.play ? 1 : 0}`;
  }

  public get videoInfos() {
    return VideoService.validateVideoUrl(this.url);
  }

  public get allow() {
    return this.allowParameters.toString().replaceAll(',', ';');
  }

  public async getThumbnail() {
    try {
      const { urlApi, source } = VideoService.validateVideoUrl(this.url);
      this.source = source;
      if (urlApi) {
        const { data } = await VideoService.getVideoDetails(urlApi);
        this.thumbnail = data.thumbnail_url;
      }
    } catch (error) {
      this.overlay = false;
    }
  }
}
