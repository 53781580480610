
import { VueConstructor } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

import { ThumbDetails } from '@/models';

import LoadingLayout from './VideoDetailsTypes/LoadingLayout.vue';
import WarningLayout from './VideoDetailsTypes/WarningLayout.vue';
import VimeoLayout from './VideoDetailsTypes/VimeoLayout.vue';
import YoutubeLayout from './VideoDetailsTypes/YoutubeLayout.vue';

const thumbs: { [index: string]: VueConstructor } = {
  [ThumbDetails.LOADING]: LoadingLayout,
  [ThumbDetails.VIMEO]: VimeoLayout,
  [ThumbDetails.WARNING]: WarningLayout,
  [ThumbDetails.YOUTUBE]: YoutubeLayout,
};

@Component({
  name: 'VideoDetailsFactory',
})
export default class VideoDetailsFactory extends Vue {
  public static makeThumb(thumb: ThumbDetails): VueConstructor {
    return thumbs[thumb];
  }
}
