
import { Component, Prop, Vue } from 'vue-property-decorator';

import Arquivo from '@/assets/icons/Arquivo.svg';
import Close from '@/assets/icons/Close.svg';

import { FileUpload } from '@/models';

@Component({
  name: 'FileBox',
  components: {
    Arquivo,
    Close,
  },
})
export default class FileBox extends Vue {
  @Prop({ required: true }) public file: FileUpload;
  @Prop({ default: true }) public canClose!: boolean;

  public closeFile() {
    this.$emit('close');
  }

  public openFile() {
    window.open(this.file.src, '_blank');
  }
}
