
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import Chip from '@/components/shared/Chip.vue';
import Suggestor from '@/components/shared/Suggestor.vue';

import {
  ChipType,
  SuggestorType,
  KeywordModel,
  KeywordPaginated,
} from '@/models';

import { KeywordService } from '@/services';

@Component({
  name: 'Keywords',
  components: {
    Chip,
    Suggestor,
  },
})
export default class Keywords extends Vue {
  @Prop({ default: 3 }) limit!: number;
  @Prop({ default: 3 }) minToSearch!: number;
  @Prop({ default: 'Buscar por palavra-chave' }) placeholder!: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: () => [] }) data: ChipType[];

  $refs!: {
    boxSearch: HTMLInputElement;
  };

  private currentChips: ChipType[] = [];
  public keywords: SuggestorType[] = [];
  private debounce: number;

  get chips(): ChipType[] {
    return this.currentChips;
  }

  set chips(data: ChipType[]) {
    this.currentChips = data;
  }

  mounted() {
    this.updateChips();
  }

  @Watch('data')
  onDataChange() {
    this.updateChips();
  }

  private updateChips() {
    this.currentChips = this.data;
  }

  public searchDebounce() {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => this.onSearch(), 300);
  }

  public onSearch(): void {
    const value = this.$refs.boxSearch?.value.trim();
    const canSuggest = value.length >= this.minToSearch;

    if (canSuggest) this.getKeywords(value);
    else this.resetKeywords();
  }

  public hadleKeywordInsert(): void {
    const value = this.$refs.boxSearch.value.trim();
    const canAdd = !this.hasOnChips(value) && (value.trim().length > 0);

    if (canAdd) {
      this.handleKeywordSelection(value);
    }
  }

  public handleKeywordSelection(keyword: string): void {
    this.createKeyword(keyword);
    this.resetBox();
    this.resetKeywords();
  }

  private getKeywords(text: string) {
    KeywordService
      .getKeywords(text)
      .then((palavrasChave: KeywordPaginated) => {
        if (this.chips?.length < this.limit) {
          this.keywords = palavrasChave.data
            .filter((i: KeywordModel) => !this.hasOnChips(i.keyword))
            .map((a: KeywordModel, idx: number) => new SuggestorType(a.keyword, idx));
        }
      })
      .catch((err: string) => console.error(err));
  }

  private resetKeywords(): void {
    this.keywords = [];
  }

  private resetBox(): void {
    const input = this.$refs.boxSearch;
    if (input) input.value = '';
  }

  private hasOnChips(name: string): boolean {
    if (!this.chips?.length) return false;

    return this.chips
      .map((n: ChipType) => n.name.toLowerCase())
      .includes(name.toLowerCase());
  }

  public focusToSearch(): void {
    const input = this.$refs.boxSearch;
    if (input) input.focus();
  }

  public removeKeyword(name: string): void {
    this.chips = this.chips.filter((chip) => chip.name !== name);
    this.$emit('change', this.chips);
  }

  private createKeyword(inputText: SuggestorType | string): void {
    const keyword: ChipType = (typeof inputText === 'string')
      ? new ChipType(inputText.trim())
      : new ChipType(inputText.name, inputText.id);

    this.chips.push(keyword);

    this.$emit('change', this.chips);
  }
}
