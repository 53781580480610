
import { Component, Prop, Vue } from 'vue-property-decorator';

import Close from '@/assets/icons/Close.svg';

import { ChipType } from '@/models';

@Component({
  name: 'Chip',
  components: {
    Close,
  },
})
export default class Chip extends Vue {
  @Prop({ required: true }) public chip: ChipType;
  @Prop({ default: false }) disabled: boolean;

  public close() {
    if (this.disabled) return;
    this.$emit('close');
  }
}
