import Vue, { VueConstructor } from 'vue';
import Component from 'vue-class-component';

import {
  DataConfirmation, ModalDataTransfer, ModalTipos,
} from '@/models';

import { ModalService } from '@/services';

import { EventBus } from '@/utils';

const { CONFIRMACAO, LOADING, FILTER_REPORT } = ModalTipos;

@Component({
  name: 'PublicationModalFlow',
})
export default class PublicationModalFlow extends Vue {
  private phrase = '';

  public setLoadingPhrase(phrase: string) {
    this.phrase = phrase;
  }

  mounted() {
    EventBus.$on(CONFIRMACAO, this.chosenResponse);
    EventBus.$on(LOADING, this.publishRequest);
  }

  beforeDestroy() {
    EventBus.$off(CONFIRMACAO, this.chosenResponse);
    EventBus.$off(LOADING, this.publishRequest);
  }

  openModal<T = string>(tipo: ModalTipos, data?: DataConfirmation<T>, fechavel = false): void {
    const handleData = tipo === CONFIRMACAO || tipo === FILTER_REPORT
      ? data
      : undefined;
    const phrase = tipo === LOADING ? this.phrase : null;
    const modal = this.getModal<T>(tipo, fechavel, handleData, phrase);
    ModalService.abrirModal(modal);
  }

  confirmData<T = string>(
    allowText: string,
    denyText: string,
    component: VueConstructor,
    phrase: T | undefined = undefined,
  ): DataConfirmation<T> {
    return {
      allowText,
      denyText,
      component,
      phrase,
    };
  }

  getModal<T = string>(
    ModalTipo: ModalTipos,
    fechavel: boolean,
    data?: DataConfirmation<T> | null,
    phrase?: string | null,
  ): ModalDataTransfer {
    return {
      component: ModalTipo,
      fechavel,
      ...(phrase && !data) && { props: { phrase } },
      ...(data && !phrase) && { props: { data } },
    };
  }

  chosenResponse(resposta: boolean) {
    if (resposta) {
      this.openModal(LOADING);
      EventBus.$emit(LOADING);
      return;
    }

    this.whenModalResponseIsDeny();
  }

  whenModalResponseIsDeny() {
    // CLASS TO OVERRIDE
  }

  publishRequest(): void {
    // CLASS TO OVERRIDE
  }
}
