
import { Component, Prop, Vue } from 'vue-property-decorator';

import FileBox from '@/components/shared/FileBox.vue';
import TextEditor from '@/components/shared/TextEditor.vue';
import VideoPlayer from '@/components/shared/VideoPlayer.vue';

import { StatementData } from '@/models';

@Component({
  name: 'Statement',
  components: {
    FileBox,
    TextEditor,
    VideoPlayer,
  },
})
export default class Statement extends Vue {
  @Prop({ required: true }) data: StatementData;
}
