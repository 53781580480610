
import { Component, Prop, Vue } from 'vue-property-decorator';
import AdicionarImagem from '@/assets/icons/AdicionarImagem.svg';

@Component({
  name: 'CoverSelector',
  components: {
    AdicionarImagem,
  },
})
export default class CoverSelector extends Vue {
  @Prop({ default: '' }) public background: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop() text: string;
}
