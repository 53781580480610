
import { Component, Vue } from 'vue-property-decorator';

import Info from '@/assets/icons/Info.svg';

@Component({
  name: 'WarningLayout',
  components: {
    Info,
  },
})
export default class WarningLayout extends Vue { }
