
import { Component, Prop, Vue } from 'vue-property-decorator';

import Vimeo from '@/assets/icons/Vimeo.svg';

import { VimeoVideoDetails } from '@/models/api';

@Component({
  name: 'VimeoLayout',
  components: {
    Vimeo,
  },
})
export default class VimeoLayout extends Vue {
  @Prop({}) public data: VimeoVideoDetails;
  @Prop({}) public url: string;
}
