
import { Component, Prop, Vue } from 'vue-property-decorator';

import Youtube from '@/assets/icons/Youtube.svg';

import { YoutubeVideoDetails } from '@/models/api';

@Component({
  name: 'YoutubeLayout',
  components: {
    Youtube,
  },
})
export default class YoutubeLayout extends Vue {
  @Prop({}) public data: YoutubeVideoDetails;
  @Prop({}) public url: string;
}
