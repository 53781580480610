
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import MaterialBox from '@/components/shared/MaterialBox.vue';
import Plus from '@/assets/icons/Plus.svg';
import { MaterialData } from '@/models';

@Component({
  name: 'AddMaterial',
  components: {
    MaterialBox,
    Plus,
  },
})
export default class AddMaterial extends Vue {
  @Prop({}) public materials!: MaterialData[];

  public adding = false;

  @Watch('materials')
  public onChangeMaterials() {
    if (!this.materials.length) this.adding = false;
  }

  public selectMaterial(material: MaterialData) {
    this.$emit('onSelect', material);
  }
}
