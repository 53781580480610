import Vue from 'vue';
import Component from 'vue-class-component';

import Camera from '@/assets/icons/Camera.svg';
import Arquivo from '@/assets/icons/Arquivo.svg';

import AttachmentBox from '@/components/shared/AttachmentBox.vue';
import VideoSearch from '@/components/shared/VideoSearch.vue';

import {
  MaterialData,
  OptionalSection,
  OptionalSectionData,
  OptionalSectionRequirements,
  OptionalToRenderId,
} from '@/models';

interface CommonOptions {
  id: number;
}

const { VIDEO, ATTACHMENT } = OptionalToRenderId;

@Component({
  name: 'AssignmentOptionals',
  components: {
    AttachmentBox,
    VideoSearch,
  },
})
export default class AssignmentOptionals extends Vue {
  public materials: MaterialData[] = [];
  public materialsToRender: Array<MaterialData> = [
    new MaterialData(ATTACHMENT, 'Arquivos', Arquivo, 0.65),
    new MaterialData(VIDEO, 'Video', Camera, 1),
  ];

  public optionalSectionsToRender: OptionalSection[] = [];
  public optionalSections: Array<OptionalSection> = [
    {
      id: ATTACHMENT,
      title: 'Arquivos',
      description: 'Inclua materiais para complementar seu trabalho.',
      optional: true,
      component: AttachmentBox,
      props: {
        files: [],
      },
    },
    {
      id: VIDEO,
      title: 'Videos',
      description: 'Inclua o link do seu vídeo.',
      optional: true,
      component: VideoSearch,
      props: {
        value: '',
      },
    },
  ];

  public updateSections(
    { videoIsOptional, attachmentIsOptional }: OptionalSectionRequirements,
    { files, value }: OptionalSectionData,
  ) {
    const requirementById = (id: OptionalToRenderId) => {
      if (id === VIDEO) return videoIsOptional;
      return id === ATTACHMENT ? attachmentIsOptional : true;
    };

    const buildPropById = (id: OptionalToRenderId) => {
      if (id === VIDEO) return { value };
      return id === ATTACHMENT ? { files } : {};
    };

    this.optionalSections = this.optionalSections.map((section) => ({
      ...section, optional: requirementById(section.id), props: buildPropById(section.id),
    }));

    this.optionalSectionsToRender = this.optionalSectionsToRender.map((section) => ({
      ...section, optional: requirementById(section.id), props: buildPropById(section.id),
    }));
  }

  private filterOptionals<T extends CommonOptions>(id: OptionalToRenderId, source: T[]) {
    return source.filter((t) => t.id !== id);
  }

  private findOption<T extends CommonOptions>(id: OptionalToRenderId, source: T[]) {
    return source.find((o) => o.id === id);
  }

  public fromMaterial(material: MaterialData) {
    const { id } = material;

    this.materialsToRender = this.filterOptionals<MaterialData>(
      id,
      this.materialsToRender,
    );

    const section = this.findOption(
      id,
      this.optionalSections,
    ) as OptionalSection;

    this.materials.push(material);
    this.optionalSectionsToRender.push(section);
  }

  public fromSection(section: OptionalSection) {
    const { id } = section;

    this.optionalSectionsToRender = this.filterOptionals<OptionalSection>(
      id,
      this.optionalSectionsToRender,
    );

    const material = this.findOption(id, this.materials) as MaterialData;

    this.optionalSections.push(section);
    this.materialsToRender.push(material);
  }
}
