
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';

import { ThumbDetails, VideoDetailsData, VideoSource } from '@/models';

import VideoDetailsFactory from './VideoDetailsFactory.vue';

@Component({
  name: 'VideoDetails',
})
export default class VideoDetails extends Vue {
  @Prop({}) public data: VideoDetailsData;

  public get component(): VueConstructor {
    const { data, source } = this.data;

    const detail = {
      [VideoSource.INVALID]: ThumbDetails.WARNING,
      [VideoSource.VIMEO]: ThumbDetails.VIMEO,
      [VideoSource.YOUTUBE]: ThumbDetails.YOUTUBE,
    };

    const thumb = !data && source !== VideoSource.INVALID
      ? ThumbDetails.LOADING
      : detail[source];

    return VideoDetailsFactory.makeThumb(thumb);
  }

  public get componentProps() {
    return { data: this.data.data, url: this.data.url };
  }
}
