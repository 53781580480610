
import {
  Component,
  Prop,
  Vue,
}
  from 'vue-property-decorator';

import FileBox from '@/components/shared/FileBox.vue';
import BigDashedButton from '@/components/shared/BigDashedButton.vue';

import { UploadDeArquivo } from '@/models/api';
import { FileUpload } from '@/models';

@Component({
  name: 'AttachmentBox',
  components: {
    FileBox,
    BigDashedButton,
  },
})
export default class AttachmentBox extends Vue {
  @Prop({ default: () => ['.pdf', '.png', '.jpg', '.jpeg', '.mp3'] })
  extensions: string[];
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: () => [] }) files!: FileUpload[];

  public newFiles: File[] = [];

  $refs!: {
    addAttachment: HTMLInputElement;
  };

  get allFiles() {
    return [...this.files, ...this.newFiles];
  }

  public getFilename(file: UploadDeArquivo) {
    return file.name || file.nome_arquivo;
  }

  public forAttachment() {
    this.$refs.addAttachment.click();
  }

  public removerAnexo(name: string) {
    const files = this.files.filter((file) => file.name !== name);
    this.newFiles = this.newFiles.filter((file) => file.name !== name);
    const dataToEmit = { files, newFiles: this.newFiles };
    this.$emit('change', dataToEmit);
  }

  public attachmentChanges() {
    const { files } = this.$refs.addAttachment;
    if (files) {
      const list = this.convertFilesToArray(files);
      this.newFiles = this.filterArrayFiles(this.newFiles, list);
    }

    this.$emit('change', { files: this.files, newFiles: this.newFiles });
  }

  private filterArrayFiles(oldFiles: File[], newFiles: File[]): File[] {
    const oldFilesName = new Set(oldFiles.map((f) => f.name));
    const callbackFilter = (file: File) => !oldFilesName.has(file.name)
      && this.validateExtension(file.name);

    return [...oldFiles, ...newFiles.filter(callbackFilter)];
  }

  private validateExtension(filename: string): boolean {
    const splited = filename.split('.');
    const ext = `.${splited[splited.length - 1]}`;

    return this.extensions.includes(ext);
  }

  private convertFilesToArray(list: FileList): File[] {
    const files = [];

    for (let i = 0; i < list.length; i++) {
      files.push(list[i]);
    }

    return files;
  }
}
