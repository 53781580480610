
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import TextEditor from '@/components/shared/TextEditor.vue';
import { QuestionModel } from '@/models';

@Component({
  name: 'Question',
  components: {
    TextEditor,
  },
})
export default class Question extends Vue {
  @Prop({ required: true }) data: QuestionModel;
  @Prop({}) index: number;
  @Prop({}) hasDivisory!: boolean;
  @Watch('data')
  onPropertyChanged(data: QuestionModel) {
    this.response = data.answer;
  }

  mounted() {
    this.response = this.data.answer;
  }

  public response: string | undefined = '';

  public onChanges() {
    this.$emit(
      'onChanges',
      {
        ...this.data,
        answer: this.response,
      },
    );
  }
}
