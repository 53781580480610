
import { Component, Mixins } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import AssignmentOptionals from '@/mixins/AssignmentOptionals';
import PublicationModalFlow from '@/mixins/PublicationModalFlow';

import Header from '@/components/Header/Header.vue';
import CoverSelector from '@/components/Assignment/CoverSelector.vue';
import Toolbar from '@/components/shared/Toolbar.vue';
import Statement from '@/components/Assignment/Statement.vue';
import Question from '@/components/Assignment/Question.vue';
import AssignmentSection from '@/components/Assignment/AssignmentSection.vue';
import Keywords from '@/components/shared/Keywords.vue';
import AddMaterial from '@/components/Assignment/AddMaterial.vue';
import PrivacyTerms from '@/components/shared/PrivacyTerms.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import PhraseConfirm from '@/components/Assignment/PhraseConfirm.vue';
import Accordion from '@/components/Accordion/Accordion.vue';
import ProfessorComments from '@/components/Accordion/AccordionType/ProfessorComments.vue';
import Snackbar from '@/components/shared/Snackbar.vue';

import {
  ModalTipos,
  SidebarTypes,
  ChipType,
  AssignmentObjectModel,
  QuestionModel,
  AssignmentMaterial,
  Delivery,
  RouteName,
  StatementData,
  CoverList,
  RenderOptionalsEvents,
  OptionalToRenderId,
  MaterialData,
  OptionalSectionRequirements,
  OptionalSectionData,
  UserModel,
  AccordionDataModel,
  AccordionTypes,
  ButtonDefaultColors,
  CommentModel,
} from '@/models';

import { BuscaTermosPrivacidade } from '@/models/api';

import { SidebarService, PrivacyTermsService } from '@/services';

import { EventBus } from '@/utils';

const { PUBLISHED_ASSIGNMENT, PENDING_ASSIGNMENTS } = RouteName;
const { VIDEO, ATTACHMENT } = OptionalToRenderId;
const { PROFESSOR_COMMENTS } = AccordionTypes;
const { BLACK } = ButtonDefaultColors;

@Component({
  name: 'Assignment',
  computed: {
    ...mapGetters('privacyTerms', {
      terms: 'terms',
    }),
    ...mapGetters('assignment', {
      getAgreeTerms: 'agreeTerms',
      getAnswers: 'answers',
      enableToPublish: 'enableToPublish',
      getAssignmentMaterials: 'assignmentMaterials',
      getCover: 'cover',
      getKeywords: 'keywords',
      getSaving: 'saving',
      getTitle: 'title',
      unsavedChanges: 'unsavedChanges',
      getWorkId: 'workId',
      getWorkToFill: 'workToFill',
      getStatement: 'statement',
      getAddMaterialsOpen: 'addMaterialsOpen',
      attachmentIsRequired: 'attachmentIsRequired',
      videoIsRequired: 'videoIsRequired',
      professorComments: 'professorComments',
      composingGrade: 'composingGrade',
    }),
    ...mapGetters('user', {
      hasPublishedAssignments: 'hasPublishedAssignments',
    }),
    ...mapGetters('headerDisplay', {
      mediumView: 'mediumView',
    }),
  },
  methods: {
    ...mapActions('assignment', {
      setAgreeTerms: 'setAgreeTerms',
      setAnswers: 'setAnswers',
      setAssignmentMaterials: 'setAssignmentMaterials',
      setCover: 'setCover',
      setKeywords: 'setKeywords',
      setSaving: 'setSaving',
      setTitle: 'setTitle',
      setWorkId: 'setWorkId',
      setWorkToFill: 'setWorkToFill',
      setStatement: 'setStatement',
      setaddMaterialsOpen: 'setaddMaterialsOpen',
      publishAssignment: 'publishAssignment',
      setCoverDownload: 'setCoverDownload',
    }),
    ...mapActions('user', {
      updateUserInfo: 'updateUserInfo',
    }),
    ...mapActions('headerDisplay', {
      checkWidthSize: 'checkWidthSize',
    }),
  },
  components: {
    Header,
    CoverSelector,
    Toolbar,
    Statement,
    Question,
    AssignmentSection,
    Keywords,
    AddMaterial,
    PrivacyTerms,
    ButtonDefault,
    PhraseConfirm,
    Accordion,
    ProfessorComments,
    Snackbar,
  },
})
export default class Assignment extends Mixins(
  AssignmentOptionals,
  PublicationModalFlow,
) {
  $refs: {
    scrollLimit: HTMLElement;
  };

  public setAgreeTerms!: (agreeTerms: boolean) => void;
  public setAnswers!: (answers: QuestionModel[]) => void;
  public setAssignmentMaterials!: (assignmentMaterials: AssignmentMaterial) => void;
  public setCover!: (cover: string) => void;
  public setKeywords!: (keywords: ChipType[]) => void;
  public setSaving!: (saving: boolean) => void;
  public setTitle!: (title: string) => void;
  public setWorkId!: (workId: number) => void;
  public setWorkToFill!: (workToFill: AssignmentObjectModel) => void;
  public setStatement!: (statement: StatementData) => void;
  public setaddMaterialsOpen!: (addMaterialsOpen: boolean) => void;
  public publishAssignment!: (publishDraft: boolean) => Promise<Delivery>;
  public setCoverDownload!: (cover: string) => void;

  public terms!: BuscaTermosPrivacidade;
  public getAgreeTerms!: boolean;
  public getAnswers!: QuestionModel[];
  public getAssignmentMaterials!: AssignmentMaterial;
  public getCover!: string;
  public getKeywords!: ChipType[];
  public getSaving!: boolean;
  public getTitle!: string;
  public unsavedChanges!: boolean;
  public enableToPublish!: boolean;
  public getWorkId!: number;
  public getWorkToFill!: AssignmentObjectModel;
  public getStatement!: StatementData;
  public getAddMaterialsOpen!: boolean;
  public mediumView: boolean;
  public professorComments: CommentModel[];

  public attachmentIsRequired!: boolean;
  public videoIsRequired!: boolean;
  public limitHit = true;

  public BLACK = BLACK;

  public accordionData: AccordionDataModel = {
    body: PROFESSOR_COMMENTS,
    data: [],
  };

  private hasPublishedAssignments: boolean;
  private showProfessorComments = false;
  private composingGrade: boolean;
  // TODO: Usar handler de erros futuramente
  private window = window;
  private updateUserInfo: () => Promise<UserModel>;
  private checkWidthSize: () => void;

  get comments(): CommentModel[] {
    return this.professorComments;
  }

  get showComments(): boolean {
    return this.showProfessorComments;
  }

  set showComments(show: boolean) {
    this.showProfessorComments = show;
  }

  get addMaterialsOpen() {
    return this.getAddMaterialsOpen || false;
  }

  set addMaterialsOpen(payload: boolean) {
    this.setaddMaterialsOpen(payload);
  }

  get agreeTerms() {
    return this.getAgreeTerms || false;
  }

  set agreeTerms(payload: boolean) {
    this.setAgreeTerms(payload);
  }

  get answers() {
    return this.getAnswers || [];
  }

  set answers(payload: QuestionModel[]) {
    this.setAnswers(payload);
  }

  get cover() {
    return this.getCover || '';
  }

  set cover(payload: string) {
    this.setCover(payload);
  }

  get assignmentMaterials() {
    return this.getAssignmentMaterials || new AssignmentMaterial([]);
  }

  set assignmentMaterials(payload: AssignmentMaterial) {
    this.setAssignmentMaterials(payload);
  }

  get keywords() {
    return this.getKeywords || [];
  }

  set keywords(payload: ChipType[]) {
    this.setKeywords(payload);
  }

  get saving() {
    return this.getSaving || false;
  }

  set saving(payload: boolean) {
    this.setSaving(payload);
  }

  get workToFill() {
    return this.getWorkToFill || new AssignmentObjectModel();
  }

  set workToFill(payload: AssignmentObjectModel) {
    this.setWorkToFill(payload);
  }

  get title() {
    return this.getTitle || '';
  }

  set title(payload: string) {
    this.setTitle(payload);
  }

  get workId() {
    return this.getWorkId || 0;
  }

  set workId(payload: number) {
    this.setWorkId(payload);
  }

  get statement() {
    return this.getStatement || new StatementData();
  }

  set statement(payload: StatementData) {
    this.setStatement(payload);
  }

  get questions() {
    return this.workToFill?.questions || [];
  }

  get snackbarText() {
    const no = !this.composingGrade ? ' não ' : ' ';
    return `A nota do assignment${no}irá compor a nota final na disciplina.`;
  }

  created() {
    this.setLoadingPhrase('Enviando assignment...');
    this.subscribeToEvents();
    this.checkWidthSize();
  }

  beforeDestroy() {
    this.unsubscribeToEvents();
  }

  private subscribeToEvents() {
    window.addEventListener('resize', this.checkWidthSize);
    window.addEventListener('scroll', this.checkScrollLimit);
    EventBus.$on(SidebarTypes.UNSPLASH, this.sidebarResponse);
    EventBus.$on(RenderOptionalsEvents.SET_OPTIONALS, this.handleOptionalSet);
  }

  private unsubscribeToEvents() {
    window.removeEventListener('resize', this.checkWidthSize);
    window.removeEventListener('scroll', this.checkScrollLimit);
    EventBus.$off(SidebarTypes.UNSPLASH, this.sidebarResponse);
    EventBus.$off(RenderOptionalsEvents.SET_OPTIONALS, this.handleOptionalSet);
  }

  private handleOptionalSet() {
    const { files, video } = this.assignmentMaterials;
    const materialById = (id: OptionalToRenderId) => this.materialsToRender?.find(
      (m) => m.id === id) as MaterialData;

    this.updateMaterial();

    if (this.attachmentIsRequired || (files && files.length)) {
      this.fromMaterial(materialById(ATTACHMENT));
    }

    if (this.videoIsRequired || (video && video.length)) {
      this.fromMaterial(materialById(VIDEO));
    }
  }

  private updateMaterial() {
    const { files, video } = this.assignmentMaterials;

    const requirements: OptionalSectionRequirements = {
      videoIsOptional: !this.videoIsRequired,
      attachmentIsOptional: !this.attachmentIsRequired,
    };

    const data: OptionalSectionData = {
      files,
      value: video,
    };

    this.updateSections(requirements, data);
  }

  public handleAgreePrivacyTermsChange(agreeTerms: boolean) {
    if (!this.agreeTerms) this.openModalTerms();
    this.agreeTerms = agreeTerms;
  }

  private openModalTerms() {
    PrivacyTermsService.openModal(this.terms, true, true);
  }

  public addMaterial(material: AssignmentMaterial | string) {
    if (typeof material === 'string') {
      this.assignmentMaterials.video = material;
    } else {
      this.assignmentMaterials.files = material.files;
      this.assignmentMaterials.newFiles = material.newFiles;
    }

    const { files, newFiles, video } = this.assignmentMaterials;

    this.assignmentMaterials = ({
      files: files ?? [],
      video: video ?? '',
      newFiles: newFiles ?? [],
    });

    this.updateMaterial();
  }

  public openSidebar() {
    SidebarService.abrirSidebar({
      component: SidebarTypes.UNSPLASH,
      fechavel: true,
    });
  }

  public sidebarResponse(resposta: CoverList) {
    this.cover = resposta.urls.regular;
    this.setCoverDownload(resposta.download);
  }

  public updatePalavraChave(chips: ChipType[]) {
    this.keywords = chips;
  }

  public handleQuestionChange(questionData: QuestionModel) {
    this.addQuestionData(questionData);
  }

  private addQuestionData(questionData: QuestionModel) {
    this.answers = this.answers
      .filter((answer) => answer.id !== questionData.id)
      .concat(questionData);
  }

  public publishRequest(navigateToPublishedAssignment = true) {
    this.saving = true;
    this.publishAssignment(navigateToPublishedAssignment).then(async ({ id }: Delivery) => {
      if (!this.hasPublishedAssignments) await this.updateUserInfo();

      if (navigateToPublishedAssignment) this.publishedNavigation(String(id));
    })
      .catch((error: string) => {
        this.window.alert(error);
        this.$router.push({ name: PENDING_ASSIGNMENTS });
      })
      .finally(() => this.saving = false);
  }

  private publishedNavigation(id: string) {
    this.$router.push({ name: PUBLISHED_ASSIGNMENT, params: { entregaId: id } });
  }

  public saveOrPublish(publish = false): void {
    if (this.enableToPublish && publish) {
      const data = this.confirmData('ENVIAR', 'AINDA NÃO', PhraseConfirm);
      this.openModal(ModalTipos.CONFIRMACAO, data);
      return;
    }

    this.publishRequest(false);
  }

  public goBack() {
    if (this.showComments && this.mediumView) {
      this.toggleComments();
      return;
    }
    this.$router.back();
  }

  private checkScrollLimit() {
    const { scrollLimit } = this.$refs;

    const limit = scrollLimit.getBoundingClientRect().top;

    this.limitHit = limit > (window.innerHeight - 495) / 2;
  }

  private toggleComments() {
    this.showComments = !this.showComments;
  }
}
